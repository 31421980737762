<template>
    <div>
		<!-- @click="open_swiper" -->
        <div @click="open_swiper(index)" class="look_img" :style="style_img" target="_blank"  :href="item" v-for="(item,index) in list" :key="item">
            <div v-if="!is_img" class="back_img" :style="{background:'url('+item+')', backgroundSize:'cover'}"></div>
			<img v-else :src="item" alt="">
        </div>
        <a-modal :visible="visible" :width="big_img_width" :footer="null" :bodyStyle="{padding:0, background:'none'}" @cancel="cancel">
            <Swiper ref="swiper_img" :options="swiper_options">
                <SwiperSlide v-for="item in list" :key="item">
                    <div class="swiper-zoom-container">
						<img class="big_img" ref="big_img" :src="item" alt/>
					</div>
                </SwiperSlide>
            </Swiper>
        </a-modal>
    </div>
</template>


<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style
import 'swiper/css/swiper.css'

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive
    },
    props: {
		is_img: {
			type: Boolean,
			default: false,
		},
		style_img:{
			type:Object,
			default(){
				return {}
			}
		},
        list: {
            type: Array,
            default() {
                return [];
            }
        }
	},
	data(){

		return {
			visible:false,
			big_img_width:520,
			swiper_options:{
				loop:true,
				zoom:{
					maxRatio:2,
				},
				on:{
					slideChangeTransitionStart:function(){
						
					},
				}
			}
		}
	},
	computed:{
		swiper(){
			return this.$refs.swiper_img.$swiper;
		}
	},
    methods: {
        open_swiper(index) {
			this.visible = true;
			this.$nextTick(()=>{
				this.big_img_width = this.$refs.big_img[index].width;
				this.swiper.slideToLoop(index,0);
			})
		},
		cancel(){
			this.swiper.zoom.out()
			this.visible = false;
		},
    }
}
</script>

<style lang="less" scoped>
.big_img{
	max-width: 800px; max-height: 600px;; margin: 0 auto; display: block;
}
.look_img {
    width: 100px;
    margin-right: 8px;
    margin-top: 10px;
    height: 100px;
    display: inline-block;
    .back_img {
        width: 100%;
        height: 100%;
    }
	img{
        height: 100%;
	}
}
</style>